<template>
  <Content :search="false">
    <template v-slot:beforeSearch>
      <div class="flex items-center text-lg font-semibold dark:text-gray-300">
        <Button icon="arrow-left" @click="goBack" />
        <h2>Jauns inventārs</h2>
      </div>
    </template>

    <template v-slot:content>
      <form class="space-y-6 w-full bg-white dark:bg-gray-750 p-4 rounded-lg">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div class="col-span-1 md:col-span-2">
            <h2 class="text-xl font-bold dark:text-gray-300">Klienta dati</h2>
          </div>

          <div class="col-span-1">
            <Autocomplete
              placeholder="Meklēt klientu no kataloga"
              url="/api/fetch_customers"
              @autocompleteItem="getCustomer"
              :params="{
                q: q.name,
                select_id: q.select_id,
                without_main_customer: true,
              }"
              :additionalFields="['address', 'reg_nr']"
            />
          </div>

          <template v-if="customer">
            <div class="col-span-1">
              <div class="flex flex-wrap h-full items-end">
                <ItemText title="Klients" :text="customer.name" />
                <ItemText title="Reģ. nr." :text="customer.reg_nr" />
              </div>
            </div>

            <template v-if="customer.branches.length > 0">
              <div class="col-span-1">
                <Select
                  v-model="form.customerBranch"
                  :errors="errors.customerBranch"
                  :items="customer.branches"
                  placeholder="Filiāle"
                />
              </div>
            </template>
          </template>
        </div>

        <template v-if="customer">
          <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div class="col-span-1 md:col-span-2">
              <h2 class="text-xl font-bold dark:text-gray-300">Inventārs</h2>
            </div>

            <div class="col-span-1">
              <Autocomplete
                placeholder="Meklēt no kataloga"
                url="/api/fetch_catalog_items"
                @autocompleteItem="getCatalogItem"
                :params="{ simple_resource: true, is_for_inventory: 1 }"
                :additionalFields="['skue', 'category']"
                :clearOnClick="true"
              />
            </div>

            <template v-for="(item, index) in inventoryItems" :key="index">
              <div
                class="
                  col-span-1
                  md:col-span-2
                  border-t border-gray-200
                  dark:border-gray-700
                  pt-4
                "
              >
                <div class="flex">
                  <div class="flex flex-wrap w-full">
                    <ItemText title="Prece" :text="item.name" />
                    <ItemText title="Preces kods" :text="item.sku" />

                    <div class="grid grid-cols-12 gap-3 w-full mt-3">
                      <div class="col-span-12 sm:col-span-6 lg:col-span-3 mb-2">
                        <Input
                          placeholder="Nosaukums"
                          v-model="item.inventoryName"
                          :errors="errors[`inventoryName-${item.id}`]"
                        />
                      </div>
                      <div class="col-span-12 sm:col-span-6 lg:col-span-3 mb-2 relative">
                        <Input
                          placeholder="PR nr."
                          :errors="errors[`uuid-${item.id}`] ? errors[`uuid-${item.id}`] : dataErrors ? dataErrors[`inventory_items.${index}.uuid`] : []"
                          v-model="item.uuid"
                          @input="clearError(`inventory_items.${index}.uuid`)"
                        />
                        <template v-if="!errors[`uuid-${item.id}`] && dataErrors && dataErrors[`inventory_items.${index}.uuid`]">
                          <a :href="`/stock/inventory_items/${item.uuid}`" target="_blank" class="absolute right-2 bottom-1.5">
                            <EyeIcon class="w-6 h-6 text-gray-800 dark:text-gray-400" />
                          </a>
                        </template>
                      </div>
                      <div class="col-span-12 sm:col-span-6 lg:col-span-3 mb-2">
                        <Input
                          placeholder="Atrašanās vieta"
                          v-model="item.location"
                        />
                      </div>
                      <div class="col-span-12 sm:col-span-6 lg:col-span-3 mb-2">
                        <Input
                          placeholder="Sērijas nr."
                          v-model="item.serial_nr"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="ml-auto">
                    <p
                      class="
                        mt-2
                        flex
                        items-center
                        text-sm
                        font-semibold
                        text-gray-900
                        dark:text-gray-300
                        sm:mt-0
                      "
                    >
                      <Button icon="close" @click="removeComponent(index)" />
                    </p>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </template>

        <div class="pt-5">
          <div class="flex justify-end">
            <button
              @click="goBack"
              type="button"
              class="
                bg-white
                dark:bg-gray-750
                py-2
                px-4
                border border-gray-300
                dark:border-gray-500
                rounded-md
                shadow-sm
                text-sm
                font-medium
                text-gray-700
                dark:text-gray-300
                hover:bg-gray-50
                dark:hover:bg-gray-770
                focus:outline-none focus:ring-0
                mr-2
              "
            >
              Atcelt
            </button>

            <template v-if="displaySubmitButton">
              <template v-if="!loading">
                <button
                  type="button"
                  class="
                    flex
                    justify-center
                    py-2
                    px-4
                    border border-transparent
                    rounded-md
                    shadow-sm
                    text-sm
                    font-medium
                    text-white
                    bg-gradient-to-r
                    from-button2-from
                    to-button2-to
                    hover:from-button2-to hover:to-button2-from
                    focus:outline-none focus:border-none
                    transition
                    duration-500
                    ease-in-out
                  "
                  @click.prevent="submit"
                >
                  Pievienot
                </button>
              </template>
              <template v-else>
                <Loading />
              </template>
            </template>
          </div>
        </div>
      </form>
    </template>
  </Content>
</template>

<script>
import { mapGetters } from "vuex";
import Select from "@/components/Components/Select";
import Autocomplete from "@/components/Components/Autocomplete";
import Input from "@/components/Components/Input";
import ItemText from "@/components/Components/ItemText";
import Loading from "@/components/Components/Loading";
import Validation from "@/modules/Validation";

import {
  EyeIcon
} from "@heroicons/vue/outline";

export default {
  name: "CreateInventoryItem",
  components: {
    Select,
    Autocomplete,
    Input,
    ItemText,
    Loading,
    EyeIcon,
  },
  data: () => ({
    form: {
      customerBranch: null,
    },
    customer: null,
    catalogItem: null,
    inventoryItems: [],
    dataErrors: null,
    formValidation: {},
    q: {},
  }),
  created() {
    this.q.name = this.$route.query.name;
    this.q.select_id = this.$route.query.id;
    this.q.customer_branch_id = this.$route.query.customer_branch_id;
  },
  computed: {
    ...mapGetters({
      loading: "loading",
      errors: "errors",
    }),
    displaySubmitButton: function () {
      return this.customer && this.inventoryItems.length > 0;
    },
    // formValidation() {
    //   return {
    //   }
    // }
  },
  watch: {
    customer() {
      if (this.customer) {
        if (this.customer.branches.length > 0) {
          const defaultCustomerBranch = this.customer.branches.find(
            (branch) => branch.id == this.$route.query.customer_branch_id
          );

          defaultCustomerBranch
            ? (this.form.customerBranch = defaultCustomerBranch)
            : (this.form.customerBranch = this.customer.branches[0]);
        }
      }
    },
    catalogItem() {
      if (this.catalogItem) {
        this.catalogItem["inventoryName"] = this.catalogItem.name;
        this.catalogItem["uuid"] = null;
        this.catalogItem["location"] = null;
        this.catalogItem["purposeId"] = 3;
        this.catalogItem["errorMessage"] = "";
        this.catalogItem["branch_id"] = null;

        this.inventoryItems.push(this.catalogItem);
        this.catalogItem = null;

        this.setInventoryItemErrors();
      }
    },
  },
  methods: {
    setInventoryItemErrors() {
      this.formValidation = {};
      this.inventoryItems.map((item) => {
        this.formValidation[`inventoryName-${item.id}`] = {
          rules: ["required"],
        };
        this.formValidation[`uuid-${item.id}`] = { rules: ["required"] };
      });
    },
    getInventoryItemsValidationValues() {
      const form = {};
      this.inventoryItems.map((item) => {
        form[`inventoryName-${item.id}`] = item.inventoryName;
        form[`uuid-${item.id}`] = item.uuid;
      });

      return form;
    },
    getCustomer(value) {
      this.customer = value;
    },

    getCatalogItem(value) {
      this.catalogItem = value;
    },

    removeComponent(index) {
      this.inventoryItems.splice(index, 1);
      this.setInventoryItemErrors();
    },

    goBack() {
      this.$router.go(-1);
    },

    formatInventoryItems() {
      return this.inventoryItems.map((inventoryItem) => {
        return {
          uuid: inventoryItem.uuid,
          name: inventoryItem.inventoryName,
          catalog_item_id: inventoryItem.id,
          location: inventoryItem.location,
          purpose_id: inventoryItem.purposeId,
          customer_id: this.customer.id,
          branch_id: this.form.customerBranch.id,
          serial_nr: inventoryItem.serial_nr,
        };
      });
    },
    checkErrors() {
      for (const [key, error] of Object.entries(this.dataErrors)) {
        const index = parseInt(key.split(".")[1]);
        this.inventoryItems[index].errorMessage = error[0];
      }
    },
    resetErrors() {
      this.inventoryItems.forEach((item) => {
        item.errorMessage = "";
      });
    },
    clearError(value) {
      if (this.dataErrors && this.dataErrors[value]) {
        this.dataErrors[value] = null
      }
    },
    submit() {
      this.$Progress.start();
      if (
        Validation(
          this.formValidation,
          this.getInventoryItemsValidationValues()
        )
      ) {
        this.$store
          .dispatch("createNewInventoryItems", {
            inventory_items: this.formatInventoryItems(),
          })
          .catch((error) => {
            this.dataErrors = error.response.data.errors;
            this.$Progress.fail();
            this.checkErrors();
          });
      } else this.$Progress.fail();
    },
  },
};
</script>

<style>
</style>